<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">ব্যবহারের শর্তাবলি</h2>
      <img src="../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Services</a></li>
                <li class="breadcrumb-item active" aria-current="page">Service Details</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <div class="service_details">
                <h3 class="service_name">ব্যবহারের শর্তাবলি</h3>
                <b>ব্যবহারের শর্তাবলি</b>
                <p>আমাদের ওয়েবসাইট দেখার জন্য আপনাকে ধন্যবাদ। কৃষি মন্ত্রণালয়ের কৃষি সেবা পোর্টাল ওয়েব সাইট এ মন্ত্রণালয়ের সেবা সংক্রান্ত হালনাগাদ তথ্য সরবরাহের একটি উদ্যোগ। এই ওয়েবসাইটটি ব্যবহার করার জন্য আপনাকে অবশ্যই কিছু শর্তাবলী মেনে চলতে হবে, যা আপনি এই সাইটে প্রবেশ করা মাত্রই প্রযোজ্য।</p>
                <b>শর্তাবলি :</b>
                <p>১. কৃষি মন্ত্রণালয়/প্রধানমন্ত্রীর কার্যালয় ওয়েবসাইটের সাথে লিংককৃত অন্যান্য সাইটের কোন তথ্যের জন্য কোন ধরণের আর্থিক সহায়তা প্রদান করে না।</p>
                <p>২. এই ওয়েবসাইটের তথ্য এবং লিংককৃত ওয়েবসাইটের তথ্য ব্যবহার করার ফলে প্রত্যক্ষ বা অপ্রত্যক্ষভাবে কোন ক্ষতির সম্মুখীন হলে তার জন্য কোন দায়দায়িত্ব এই কার্যালয় গ্রহণ করবে না।</p>
                <p>৩. এই ওয়েবসাইটের কর্মকাণ্ডের কোন ধরনের অবিচ্ছিন্নতার জন্য নিশ্চয়তা প্রদান করবে না।</p>
                <b>কপিরাইট</b>
                <p>এই ওয়েবসাইটে যেসব কনটেন্ট, তথ্যাদি এবং ট্রেডমার্কস প্রদর্শিত হচ্ছে কিংবা এই ওয়েবসাইটের সাথে লিংককৃত অন্যান্য ওয়েবসাইটের সমস্ত তথ্যাদির কপিরাইট তাদের নিজস্ব এবং তা কপিরাইট আইন (সংশোধিত ২০০৫ সালে) এর মাধ্যমে সংরক্ষিত।</p>
                <b>তথ্যের কাজ এবং প্রিন্ট</b>
                <p>এই ওয়েবসাইটের সকল ব্যবহারকারী ওয়েবসাইটে প্রদর্শিত সকল তথ্যের কোন রকম পরিমার্জন, সংযুক্তিকরণ এবং সংশোধন ব্যতীত প্রিন্ট করতে পারবেন। কিন্তু এই পোটার্লে প্রকাশিত কোন তথ্য যা বাংলাদেশ সরকারের নয় এবং যাতে অন্য কোনো সংস্থার কপিরাইট রয়েছে সেক্ষেত্রে সে সংস্থার অনুমতি গ্রহণ করতে হবে।</p>
                <b>অন্যান্য ওয়েবসাইটের সঙ্গে সংযোগ</b>
                <p>এই ওয়েবসাইটের সঙ্গে অন্যান্য সাইটের সংযোগ রয়েছে যা কৃষি মন্ত্রণালয়/প্রধানমন্ত্রীর কার্যালয় কর্তৃক পরিচালিত নয় কিংবা এর নিয়ন্ত্রণাধীন নয়। আমরা এই ধরনের সংযোগকৃত ওয়েবসাইটের কনটেন্ট এবং তা সবসময় কার্যকর রাখার জন্য দায়বদ্ধ নই।</p>
                <b>প্রবেশাধিকার</b>
                <p>কোন বিশেষ ব্যক্তি অথবা ইন্টারনেট থেকে  ব্রাউজকৃত কোন বিশেষ ঠিকানাকে কোন প্রকার কারণ দর্শন ব্যতীত এ ওয়েবসাইটে প্রবেশাধিকারে নিষেধাজ্ঞা আরোপ করা হতে পারে।</p>
                <b>নীতিমালা সংযোজন এবং পরিবর্তনের নোটিশ</b>
                <p>কোন প্রকার নোটিশ ব্যতীত যে কোন সময় এই নীতিমালা সংশোধন করা হতে পারে। যেকোন তথ্যাদি যা বর্তমান নীতিমালার মাধ্যমে সংরক্ষণ করা হয়েছিল তা এসব শর্তাবলী মেনে চলবে। নীতিমালার পরিবর্তনের পর যদি কোন তথ্যাদি সংগ্রহ করা হয় তা অবশ্যই পরিবর্তিত নীতিমালার মাধ্যমে পালনীয় হবে।</p>
                <b>গভর্নিং এবং বিচার ব্যবস্থা</b>
                <p>এইসব শর্তাবলী বাংলাদেশের আইন অনুযায়ী পরিচালিত হচ্ছে। কোন ধরনের আপত্তি বাংলাদেশের বিচার ব্যবস্থার মাধ্যমে মীমাংসিত হবে।</p>
                <br><br>
                <address>
                  <b>বিস্তারিত তথ্যের জন্য যোগাযোগ করুন</b><br/>
                  কৃষি মন্ত্রণালয়<br>
                  বাংলাদেশ সচিবালয়<br>
                  রমনা, ঢাকা-১০০০<br>
                  বাংলাদেশ।
                </address>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0
  }
}
</script>
